import { createRoot } from "react-dom/client";
import { loadRuntimeConfig } from "./runtimeConfig/loader";
import { stringifyError } from "./tools/errors";

/**
 * Wait for runtimeConfig to be available before bootstrapping the app.
 * This allows us to use non-optional types for RuntimeConfig.
 */
loadRuntimeConfig()
  .then(() => import("./App"))
  .then(({ default: App }) => {
    const root = createRoot(document.getElementById("root")!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
    root.render(<App />);
  })
  .catch((err) => {
    const errorMsg = encodeURIComponent(stringifyError(err));

    window.location.replace(`/failed-to-load-app.html?error=${errorMsg}`);
  });

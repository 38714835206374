import { Application, getRuntimeConfig } from ".";
import { isObject } from "../tools/utils";
import { getAppVersion } from "../tools/versionInfo";

/**
 * The app's runtimeConfig definition is stored on the API so that it can be
 * modified via the backoffice. This config is required before we can bootstrap
 * the app so it needs to be loaded first.
 *
 * When running locally the config is loaded from the local filesystem.
 */
export const loadRuntimeConfig = async (): Promise<void> => {
  await fetchRuntimeConfig();
  validateRuntimeConfig();
};

/**
 * @NOTE The config is embedded as a javascript file (JSONP) so that we can use
 * the same approach for both local and remote config, while maintaining TS
 * coverage of the local config file.
 */
const fetchRuntimeConfig = (): Promise<void> => {
  const { collaboardApiUrl } = window;
  const host = window.location.host;
  const useLocalConfig =
    host.startsWith("localhost") || host.startsWith("192.168.");

  // Fail fast if required server configuration is not set
  if (!useLocalConfig && (!collaboardApiUrl || !collaboardApiUrl.trim())) {
    throw new Error("collaboardApiUrl is not defined");
  }

  const configUrl = useLocalConfig
    ? "/config/env.local.js" // <--- this is used when developing locally
    : `${collaboardApiUrl}/api/CollaborationHub/GetClientSettings`;

  if (useLocalConfig) {
    // eslint-disable-next-line no-console
    console.warn(`Loading runtimeConfig from ${configUrl}`);
  }

  const version = getAppVersion();

  return loadInjectedScript(
    `${configUrl}?application=${Application.CollaBoardWeb}&client=${host}&version=${version}`
  );
};

/**
 * Basic validation to ensure that the config has loaded and the schema looks
 * broadly correct.
 *
 * The config coming from the API is outside of the control of the app, so
 * mistakes will be possible. Therefore we need to make sure that the app fails
 * quickly rather than relying on / waiting for an exception to occur in the
 * underlying code.
 *
 * @TODO #7975 - Use Yup to validate the config thoroughly against the schema.
 */
const validateRuntimeConfig = (): void => {
  /**
   * Validate the response from getRuntimeConfig() instead of
   * window.runtimeConfig directly to ensure that we are validating the same
   * object that the rest of the app consumes.
   */
  const runtimeConfig = getRuntimeConfig();

  if (!runtimeConfig) {
    throw new Error("window.runtimeConfig not found");
  }

  if (
    !runtimeConfig.env ||
    !runtimeConfig.appId ||
    !runtimeConfig.signalR ||
    !runtimeConfig.storage
  ) {
    throw new Error("window.runtimeConfig missing required property");
  }

  if (!isObject(runtimeConfig.signalR)) {
    throw new Error("window.runtimeConfig.signalR is not an object");
  }

  if (!isObject(runtimeConfig.storage)) {
    throw new Error("window.runtimeConfig.storage is not an object");
  }
};

const loadInjectedScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = src;

    script.onerror = (err) => {
      // There are no other details when a script fails to load
      // https://stackoverflow.com/a/35634509/6860493
      reject(new Error("Cannot load the runtimeConfig"));
    };

    script.onload = () => {
      resolve();
    };

    document.body.appendChild(script);
  });
};
